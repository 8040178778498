<template>
	<v-overlay :visible="visible" @close="close()">
		<div class="flex space-between align-center mb-30">
			<div v-if="id == null">
				<h1 class="mb-10">Nýggjan logg</h1>

				<p class="mb-10 color-grey">Legg ein logg afturat sáttmálanum.</p>
			</div>

			<div v-if="id != null">
				<h1 class="mb-10">Loggur</h1>

				<p class="mb-10 color-grey">{{ new Date(value.createdAt).format('%d/%m/%y %H:%M') }}</p>

				<p v-if="value && value.userName">
					{{ value.userName }}
				</p>
			</div>

			<div class="overlay-close" @click.prevent="close()"></div>
		</div>

		<div class="mb-20">
			<textarea v-model="comments" ref="textarea" rows="5" :disabled="id != null" @keydown.ctrl.enter="save"></textarea>
		</div>

		<div v-if="id == null" class="flex justify-end">
			<div class="btn inline-block" :class="{ disabled: !comments }" @click="save">Stovna</div>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';

export default {
	name: 'LogOverlay',

	props: {
		value: Object,
		visible: Boolean,
		agreementId: Number,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			id: null,
			comments: '',
		};
	},

	watch: {
		visible: function (value) {
			if (value) {
				this.$nextTick(() => {
					this.$refs.textarea.focus();
				});
			}
		},

		value: function (value) {
			if (!value) {
				return;
			}

			this.comments = value.comments;
			this.id = value.id;
		},
	},

	methods: {
		save() {
			axios
				.post(`/agreements/${this.agreementId}/log`, {
					comments: this.comments.trim(),
				})
				.then((response) => {
					this.$emit('create', response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.id = null;
					this.comments = '';
				});
		},

		close() {
			this.id = null;
			this.comments = '';

			this.$emit('close');
		},
	},
};
</script>

<style scoped>
textarea {
	resize: none;
}

textarea:disabled {
	border: none;
	background: white;
	box-shadow: none;

	padding: 0;
}
</style>
