<template>
	<main class="container-wide">
		<div class="mb-30">
			<h1 class="mb-10">Kunningarbretti</h1>

			<p class="color-grey">Yvirlit yvir sáttmálar, sum eru í gongd.</p>
		</div>

		<div class="dashboard-tabs" style="margin-bottom: 32px">
			<a href="#" @click.prevent class="dashboard-tab" :class="{ active: activeTab == 'Sáttmálar' }" @click="activeTab = 'Sáttmálar'">Sáttmálar</a>

			<a
				href="#"
				@click.prevent
				class="dashboard-tab"
				:class="{ active: activeTab == 'Stutttíðarleigu Terminalar' }"
				@click="activeTab = 'Stutttíðarleigu Terminalar'"
			>
				Stutttíðarleigu Terminalar
			</a>

			<a
				href="#"
				@click.prevent
				class="dashboard-tab"
				:class="{ active: activeTab == 'Komandi stutttíðarleigur' }"
				@click="activeTab = 'Komandi stutttíðarleigur'"
			>
				Komandi stutttíðarleigur
			</a>
		</div>

		<!-- Log Overlay -->
		<LogOverlay
			v-model="logOverlay.log"
			:visible="logOverlay.visible"
			:agreement-id="logOverlay.agreementId"
			@create="addLog"
			@close="
				logOverlay.visible = false;
				logOverlay.log = null;
			"
		/>

		<!-- Sáttmálar -->
		<template v-if="activeTab == 'Sáttmálar'">
			<div>
				<div class="flex justify-center my-40" v-if="agreementsLoading">
					<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
				</div>

				<div v-if="!filteredAgreements.length" class="text-center my-50">Ongir Sáttmálar at síggja í løtuni.</div>

				<table v-if="filteredAgreements.length && !agreementsLoading">
					<thead>
						<th class="whitespace-nowrap">Kundi</th>

						<th class="whitespace-nowrap">Slag av avtalu</th>

						<th class="whitespace-nowrap">Seinast dagført</th>

						<th class="whitespace-nowrap">Seinasti loggur</th>

						<th></th>
					</thead>

					<tbody>
						<tr class="relative" v-for="(agreement, index) in filteredAgreements" :key="`agreement-${index}`">
							<!-- Customer -->
							<td class="">
								<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
									<span :title="agreement.customer && agreement.customer.name">
										{{ agreement.customer ? agreement.customer.name : '-' }}
									</span>
								</router-link>
							</td>

							<!-- Agreement Type -->
							<td>
								<span v-if="agreement.type">{{ agreement.type.name }}</span>

								<span v-if="!agreement.type">-</span>
							</td>

							<!-- Updated At -->
							<td v-if="!agreement.agreementLink">-</td>

							<td v-else-if="isApproved(agreement)">
								<router-link :to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }" class="color-green">
									<div>Góðkend</div>

									<div class="color-grey" style="font-size: 13px; font-weight: 400">Bíðar eftir undirskrift</div>
								</router-link>
							</td>

							<td v-else-if="isSentForApproval(agreement)">
								<router-link :to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }" class="color-blue">
									<div>Góðkenning umbiðin</div>

									<div class="color-grey" style="font-size: 13px; font-weight: 400">Kundin er liðugur at fylla út</div>
								</router-link>
							</td>

							<td v-else>
								<router-link
									:to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }"
									:class="{ 'color-grey': !isInactive(agreement), 'color-red': isInactive(agreement) }"
								>
									<div>
										{{ getUpdatedAt(agreement) }}
									</div>

									<div v-if="isInactive(agreement)" class="color-grey" style="font-size: 13px; font-weight: 400">Meir enn 2 dagar síðan</div>
								</router-link>
							</td>

							<!-- Log -->
							<td class="relative log-line">
								<div
									v-if="agreement.logs && agreement.logs[agreement.logs.length - 1]"
									@click.prevent="
										logOverlay.log = agreement.logs[agreement.logs.length - 1];
										logOverlay.visible = true;
									"
									style="white-space: pre"
								>
									<div class="line-clamp-1 cursor-pointer log-comment">{{ agreement.logs[agreement.logs.length - 1].comments }}</div>
								</div>

								<div v-if="!agreement.logs || !agreement.logs[agreement.logs.length - 1]">-</div>

								<div
									class="log-add"
									@click="
										logOverlay.agreementId = agreement.id;
										logOverlay.visible = true;
									"
								>
									+
								</div>
							</td>

							<td>
								<i
									class="fas fa-eye"
									:title="
										agreement.agreementLink && agreement.agreementLink.numberOfVisits
											? `Kundin hevur sæð hetta`
											: 'Kundin hevur ikki sæð hetta enn'
									"
									:class="{ 'color-blue': agreement.agreementLink && agreement.agreementLink.numberOfVisits }"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>

		<!-- Stutttíðarleigu Terminalar -->
		<template v-if="activeTab == 'Stutttíðarleigu Terminalar'">
			<div>
				<div class="flex justify-center my-40" v-if="terminalsLoading">
					<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
				</div>

				<div v-if="!shortTermAgreementTerminals.length" class="text-center my-50">Ongir Stutttíðarleigu Terminalar at síggja í løtuni.</div>

				<table v-if="shortTermAgreementTerminals.length && !terminalsLoading">
					<thead>
						<th class="whitespace-nowrap">Terminalur</th>

						<th class="whitespace-nowrap">Kundi</th>

						<th class="whitespace-nowrap">Byrjar</th>

						<th class="whitespace-nowrap">Endar</th>

						<th class="whitespace-nowrap">Status</th>

						<th class="whitespace-nowrap">&nbsp;</th>
					</thead>

					<tbody>
						<tr class="relative" v-for="(terminal, index) in shortTermAgreementTerminals" :key="`terminal-${index}`">
							<td>
								<router-link :to="{ name: 'TerminalShow', params: { id: terminal.id } }">{{ terminal.verifoneNumber }}</router-link>
							</td>

							<!-- Customer Name -->
							<td class="whitespace-nowrap">
								<div v-if="getTerminalCustomer(terminal)">
									<router-link :to="{ name: 'AgreementShow', params: { id: getTerminalAgreement(terminal).agreementId } }">
										{{ getTerminalCustomer(terminal).name }}
									</router-link>
								</div>
							</td>

							<td v-if="getTerminalAgreement(terminal)">{{ new Date(getTerminalAgreement(terminal).from).format('%d/%m/%y %H:%M') }}</td>
							<td v-else>-</td>

							<td v-if="getTerminalAgreement(terminal)">
								{{ getTerminalAgreement(terminal).to ? new Date(getTerminalAgreement(terminal).to).format('%d/%m/%y %H:%M') : '-' }}
							</td>
							<td v-else>-</td>

							<td v-if="isLate(terminal)" class="color-red">Útgingin</td>
							<td v-else class="color-green">OK</td>

							<td>
								<i
									title="Terminalurin er afturkomin"
									@click="returnTerminal(terminal.id)"
									class="fas fa-arrow-alt-circle-left color-blue cursor-pointer hover:color-blue-600"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>

		<template v-if="activeTab == 'Komandi stutttíðarleigur'">
			<div>
				<div class="flex justify-center my-40" v-if="agreementsLoading">
					<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
				</div>

				<div v-if="!upcomingShortTermAgreements.length" class="text-center my-50">Ongar Komandi stutttíðarleigur at síggja í løtuni.</div>

				<table v-if="upcomingShortTermAgreements.length && !agreementsLoading">
					<thead>
						<th class="whitespace-nowrap">Kundi</th>

						<th class="whitespace-nowrap">Byrjar</th>

						<th class="whitespace-nowrap">Endar</th>

						<th class="whitespace-nowrap">Tal av terminalum</th>

						<th class="whitespace-nowrap">Seinast dagført</th>

						<th class="whitespace-nowrap">Seinasti loggur</th>

						<th></th>
					</thead>

					<tbody>
						<tr class="relative" v-for="(agreement, index) in upcomingShortTermAgreements" :key="`agreement-${index}`">
							<!-- Customer -->
							<td class="">
								<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
									<span :title="agreement.customer && agreement.customer.name">
										{{ agreement.customer ? agreement.customer.name : '-' }}
									</span>
								</router-link>
							</td>

							<!-- Start Time -->
							<td>
								{{ new Date(agreement.from).format('%d/%m/%y %H:%M') }}
							</td>

							<!-- End Time -->
							<td>
								{{ new Date(agreement.to).format('%d/%m/%y %H:%M') }}
							</td>

							<!-- Number Of Terminals -->
							<td>
								{{ agreement.numberOfTerminals }}
							</td>

							<!-- Updated At -->
							<td v-if="!agreement.agreementLink">-</td>

							<td v-else-if="isApproved(agreement) && agreement.approvedByUserAt">
								<router-link :to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }" class="color-green-700">
									<div>Góðkend av kunda</div>
								</router-link>
							</td>

							<td v-else-if="isApproved(agreement)">
								<router-link :to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }" class="color-green">
									<div>Góðkend</div>

									<div class="color-grey" style="font-size: 13px; font-weight: 400">Bíðar eftir undirskrift</div>
								</router-link>
							</td>

							<td v-else-if="isSentForApproval(agreement)">
								<router-link :to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }" class="color-blue">
									<div>Góðkenning umbiðin</div>

									<div class="color-grey" style="font-size: 13px; font-weight: 400">Kundin er liðugur at fylla út</div>
								</router-link>
							</td>

							<td v-else>
								<router-link
									:to="{ name: 'Client', query: { token: getAgreementLink(agreement) } }"
									:class="{ 'color-grey': !isInactive(agreement), 'color-red': isInactive(agreement) }"
								>
									<div>
										{{ getUpdatedAt(agreement) }}
									</div>

									<div v-if="isInactive(agreement)" class="color-grey" style="font-size: 13px; font-weight: 400">Meir enn 2 dagar síðan</div>
								</router-link>
							</td>

							<!-- Log -->
							<td class="relative log-line">
								<div
									v-if="agreement.logs && agreement.logs[agreement.logs.length - 1]"
									@click.prevent="
										logOverlay.log = agreement.logs[agreement.logs.length - 1];
										logOverlay.visible = true;
									"
									style="white-space: pre"
								>
									<div class="line-clamp-1 cursor-pointer log-comment">{{ agreement.logs[agreement.logs.length - 1].comments }}</div>
								</div>

								<div v-if="!agreement.logs || !agreement.logs[agreement.logs.length - 1]">-</div>

								<div
									class="log-add"
									@click="
										logOverlay.agreementId = agreement.id;
										logOverlay.visible = true;
									"
								>
									+
								</div>
							</td>

							<td>
								<i
									class="fas fa-eye"
									:title="
										agreement.agreementLink && agreement.agreementLink.numberOfVisits
											? `Kundin hevur sæð hetta`
											: 'Kundin hevur ikki sæð hetta enn'
									"
									:class="{ 'color-blue': agreement.agreementLink && agreement.agreementLink.numberOfVisits }"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</main>
</template>

<script>
import axios from 'axios';

import LogOverlay from '@/components/LogOverlay.vue';

export default {
	name: 'Dashboard',

	components: {
		LogOverlay,
	},

	data() {
		return {
			loading: false,

			activeTab: 'Sáttmálar',

			agreements: [],
			agreementsLoading: true,
			terminals: [],
			terminalsLoading: true,
			logs: [],
			logOverlay: {
				visible: false,
				log: null,
				agreementId: null,
			},
		};
	},

	mounted() {
		// Get Agreements
		axios
			.get('/agreements')
			.then((response) => {
				this.agreements = response.data;
			})
			.catch(() => {
				console.log('Error fetching agreements from E-conomic');
			})
			.finally(() => {
				this.agreementsLoading = false;
			});

		// Get Terminals
		axios
			.get('/terminals')
			.then((response) => {
				let terminals = response.data;

				this.terminals = terminals;

				this.terminals.sort((a, b) => {
					const terminalAgreementA = this.getTerminalAgreement(a);
					const terminalAgreementB = this.getTerminalAgreement(b);

					if (!terminalAgreementA) {
						return 1;
					}

					if (!terminalAgreementB) {
						return -1;
					}

					return new Date(terminalAgreementA.to) - new Date(terminalAgreementB.to);
				});
			})
			.catch(() => {
				console.log('Error fetching terminals');
			})
			.finally(() => {
				this.terminalsLoading = false;
			});
	},

	computed: {
		filteredAgreements() {
			if (!this.agreements.length) {
				return [];
			}

			return this.agreements.filter(
				(agreement) => !agreement.active && agreement.type?.name != 'Stutttíðarleiga' && agreement.type?.name != 'KYC' && !agreement.isArchived
			);
		},

		shortTermAgreementTerminals() {
			let terminals = [];

			for (const terminal of this.terminals) {
				const agreement = this.getTerminalAgreement(terminal)?.agreement;

				if (!agreement) {
					continue;
				}

				if (agreement.isArchived) {
					continue;
				}

				if (!agreement.active) {
					continue;
				}

				if (agreement.type?.name != 'Stutttíðarleiga') {
					continue;
				}

				if (terminal.hasBeenReturned) {
					continue;
				}

				terminals = [...terminals, terminal];
			}

			return terminals;
		},

		upcomingShortTermAgreements() {
			if (!this.agreements.length) {
				return [];
			}

			const upcomingShortTermAgreements = this.agreements.filter((agreement) => {
				if (agreement.type?.name != 'Stutttíðarleiga') {
					return false;
				}

				if (agreement.isArchived) {
					return false;
				}

				if (agreement.active) {
					return false;
				}

				if (agreement.isTemporarilyOnAgreement) {
					return false;
				}

				if (new Date(agreement.to).getTime() < Date.now()) {
					return false;
				}

				return true;
			});

			// Sort by 'from date'
			upcomingShortTermAgreements.sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime());

			return upcomingShortTermAgreements;
		},
	},

	methods: {
		getUpdatedAt(agreement) {
			if (!agreement.updatedAt) {
				return '';
			}

			return new Date(agreement.updatedAt).format('%d/%m/%y %H:%M');
		},

		isInactive(agreement) {
			if (!agreement.updatedAt) {
				return true;
			}

			const timeSince = Date.now() - new Date(agreement.updatedAt).getTime();

			return timeSince > 1000 * 60 * 60 * 48;
		},

		getAgreementLink(agreement) {
			if (!agreement.agreementLink) {
				return '';
			}

			return agreement.agreementLink.token;
		},

		isApproved(agreement) {
			return agreement.approved;
		},

		isSentForApproval(agreement) {
			return agreement.sentForApproval;
		},

		showTerminal(terminalId) {
			this.$router.push({ name: 'TerminalShow', params: { id: terminalId } });
		},

		isLate(terminal) {
			const agreement = this.getTerminalAgreement(terminal);

			if (new Date(agreement.to || '2100-01-01') < new Date()) {
				return true;
			}

			return false;
		},

		getTerminalAgreement(terminal) {
			const terminalAgreements = terminal.agreementTerminals;

			const lastTerminalAgreement = terminalAgreements[terminalAgreements.length - 1];

			if (!lastTerminalAgreement) {
				return null;
			}

			return lastTerminalAgreement;
		},

		getTerminalCustomer(terminal) {
			const terminalAgreement = this.getTerminalAgreement(terminal);

			if (!terminalAgreement) {
				return null;
			}

			return terminalAgreement.agreement.customer;
		},

		addLog(log) {
			const agreement = this.agreements.find((agreement) => agreement.id == this.logOverlay.agreementId);

			agreement.logs = [...agreement.logs, log];

			this.logOverlay.visible = false;
		},

		returnTerminal(terminalId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			// TODO:
			axios
				.patch(`/terminals/${terminalId}/return`)
				.then((response) => {
					this.terminals.find((terminal) => terminal.id == terminalId).to = new Date().format('%y-%m-%d %H:%M');
					this.terminals.find((terminal) => terminal.id == terminalId).hasBeenReturned = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.table-container {
	max-height: 400px;

	padding: 16px;

	border: 1px solid #e8e8e8;
	border-radius: 8px;

	background: white;

	h2 {
		margin-bottom: 24px;

		font-size: 20px;
	}
}

.dashboard-grid {
	display: grid;

	grid-template-columns: 1fr;

	gap: 64px;

	overflow: hidden;

	@media (max-width: 768px) {
		gap: 32px;
	}
}

.line-clamp-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.log-line {
	max-width: 320px;

	&:hover {
		.log-add {
			opacity: 1;
		}
	}
}

.log-add {
	position: absolute;
	top: 50%;
	right: 8px;

	width: 20px;
	height: 20px;

	transform: translateY(-50%);

	display: grid;
	place-items: center;

	color: white;
	background: var(--color-blue);

	border-radius: 50%;

	cursor: pointer;

	opacity: 0;

	&:hover {
		background: var(--color-blue-600);
	}
}

.danger {
	border: 1px solid red;
}

.dashboard-tabs {
	display: flex;

	border-bottom: 1px solid #e8e8e8;

	.dashboard-tab {
		margin-left: 4px;
		padding: 12px 20px;

		color: #333;
		background: white;

		font-size: 16px;
		font-weight: bold;

		border-radius: 8px 8px 0 0;

		transition: 0.25s all ease-in-out;

		&.active {
			color: white;
			background: var(--color-blue);
		}
	}
}

@media (max-width: 768px) {
	.dashboard-grid {
		grid-template-columns: 1fr;
	}
}
</style>
